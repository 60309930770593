<template>
  <div class="card bg-light">
    <div class="row">
      <div class="col-md-9"></div>
      <div class="col-md-2">
        <b-input-group size="sm">
          <b-form-input
            id="filterInput"
            v-model="search"
            name="search"
            type="search"
            placeholder="Type to Search"
          />
        </b-input-group>
      </div>
      <div class="col-md-1">
        <button v-on:click="onSearch" class="btn btn-secondary btn-sm">
          search
        </button>
      </div>
    </div>

    <div class="card-header">
      <table class="table table-striped">
        <thead>
          <tr>
            <th class="text-center width:10%">Campaign Name</th>
            <th class="text-center width:10%">Total Lead</th>
            <th class="text-center width:10%">Show Lead</th>
          </tr>
        </thead>

        <tr
          v-if="campaignList.length > 0"
          v-for="(item, index) in campaignList"
          v-bind:key="item.id"
        >
          <td class="text-center">{{ item["campaign"]["name"] }}</td>
          <td class="text-center">{{ item["total"] }}</td>
          <td class="text-center">
            <a
              type="btn"
              v-on:click="showDetails(item['campaign_id'])"
              class="text-center width:10% btn btn-primary btn-sm"
              target="_blank"
              ><i class="fa fa-eye" aria-hidden="true"></i
            ></a>
          </td>
        </tr>
        
      </table>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BRow,
  BCol,
  BInputGroupAppend,
  BInputGroup,
  BSpinner,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, max, mimes, size, confirmed } from "@validations";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import { permissionsConstant } from "@/helpers/permissionsConstant";

export default {
  mixins: [togglePasswordVisibility],
  name: "ListOfLead",
  components: {
    BRow,
    BCol,
    BForm,
    BButton,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BInputGroupAppend,
    BInputGroup,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      search: null,
      campaignId: "",
      campaignList: "",
      permissionsConstant,
      modelType: "",
      userId: "",
      name: "",
      password: "",
      password_confirmation: "",
      email: "",
      selectRoleId: "",
      roleIdOption: [],
      selectGenderId: "",
      genderOption: [
        { label: "Male", value: "Male" },
        { label: "Female", value: "Female" },
      ],
      selectStatusValue: true,
      statusValueOption: [
        {
          name: "Active",
          value: true,
        },
        {
          name: "Inactive",
          value: false,
        },
      ],
      selectDesignationId: "",
      designationIdOption: [],

      pageLength: 10,
      columns: [
        {
          label: "Campaign",
          field: "name",
        },
        {
          label: "No Of Leads",
          field: "designation.data.name",
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: "",
      delayTimer: null,
      isLoading: false,
      isUserFormSubmitLoading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          { field: "id", type: "desc" },
          { field: "name", type: "desc" },
        ],
        page: 1,
        perPage: 10,
      },
    };
  },

  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
    }),
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    statusVariant() {
      const statusColor = {
        true: "light-success",
        false: "light-danger",
      };

      return (status) => statusColor[status];
    },
  },

  async created() {
    try {
      const data = await Promise.all([this.getCampaign()]);
      this.campaignList = data[0]["data"]["data"];
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },

  methods: {
    async showDetails(id) {
      // this.campaignId = id;

      // const response = await this.$api.post(
      //         "/api/campaign-leads/"+id
      //       );

      const result = this.$router.push({
        name: "campaign-leads-info",
        params: { id },
      });
    },
    roleName(rowObj) {
      return rowObj?.roles?.data[0]?.name;
    },
    showModal() {
      this.$bvModal.show("modal-users-form");
    },
    hiddenModal() {
      this.modelType = "";
      this.$bvModal.hide("modal-users-form");
      this.resetModal();
    },
    resetModal() {
      this.userId = "";
      this.name = "";
      this.selectRoleId = "";
      this.email = "";
      this.selectStatusValue = true;
      this.password = "";
      this.password_confirmation = "";
      this.selectDesignationId = "";
    },
    async onShow(value) {
      
      this.modelType = "editModel";
      this.userId = value?.id;
      this.name = value?.name;
      this.email = value?.email;
      this.selectRoleId = value?.roles?.data[0]?.id;
      this.selectStatusValue = value?.status;
      this.selectDesignationId = value?.designation_id;
      this.selectGenderId = value?.gender;

      this.showModal();
    },
    async onDelete(id) {
      try {
        await this.$api.delete(`/api/users/${id}`);

        this.loadItems();

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Success",
            icon: "BellIcon",
            variant: "success",
            text: "User successfully Deleted",
          },
        });
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "BellIcon",
            variant: "danger",
            text: error?.response?.data?.message,
          },
        });
      }
    },

    async getSearch() {
      return await this.$api.post(`/api/search-campaign`, {
        search: this.search,
      });
    },

    async onSearch() {
      try {
        const data = await Promise.all([this.getSearch()]);
        this.campaignList = data[0]["data"]["data"];
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "BellIcon",
            variant: "danger",
            text: error?.response?.data?.message,
          },
        });
      }
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: params,
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },
    async getCampaign() {
      return await this.$api.get("api/campaigns-info");
    },
    async getRoles() {
      return await this.$api.get("api/roles/priority-wise");
    },
    async getDesignations() {
      return await this.$api.get("api/designations/all");
    },

    validationForm: async function () {
      this.$refs.usersValidation.validate().then(async (success) => {
        if (success) {
          try {
            if (this.modelType == "editModel") {
              this.isUserFormSubmitLoading = true;
              await this.$api.put(`/api/users/${this.userId}`, {
                name: this.name,
                email: this.email,
                status: this.selectStatusValue,
                password: this.password,
                password_confirmation: this.password_confirmation,
                role_id: this.selectRoleId,
                designation_id: this.selectDesignationId,
                gender: this.selectGenderId,
              });
              this.isUserFormSubmitLoading = false;
              this.loadItems();
              this.hiddenModal();
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "User successfully Updated",
                },
              });
            } else {
              this.isUserFormSubmitLoading = true;
              await this.$api.post("/api/users", {
                name: this.name,
                email: this.email,
                status: this.selectStatusValue,
                password: this.password,
                password_confirmation: this.password_confirmation,
                role_id: this.selectRoleId,
                designation_id: this.selectDesignationId,
                gender: this.selectGenderId,
              });
              this.isUserFormSubmitLoading = false;
              this.hiddenModal();

              this.loadItems();

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "User successfully Created",
                },
              });
            }
          } catch (error) {
            this.isUserFormSubmitLoading = false;
            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.usersValidation.setErrors(
                error?.response?.data?.errors
              );
            }
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.bg-color {
  background-color: #c0c6c9;
}
</style>
